import React, { useState, useEffect } from 'react'
import { useForm, Controller, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getComments as getCommentsApi,
         createComment as createCommentApi,
         deleteComment as deleteCommentApi,
} from './../api/getComments'
import { db } from '../firebase/firebase'
import { addDoc, collection, serverTimestamp } from "firebase/firestore"; 
//import { SMTPClient } from 'emailjs'

export const MultiStepContext = React.createContext();

const SignupSchema = yup.object().shape({
	fullname: yup.string().required(),
	states: yup.string().required,
	numofapartment: yup.number().required().positive().integer(),
	email: yup.string().email()
})


const FormContext =({children})=> {


    const [currentStep, setStep] = useState(1);
    const [date, setDate] = useState(new Date());
    const [steps, setSteps] = useState('');
    const [text, setText] = useState('');
    const [emailAddress, setEmailAddress] = useState("")
    const [fullname, setFullname] = useState('');
    const [activeComment, setActiveComment] = useState(null);
    const [backendComments, setBackendComments] = useState([]);
    const [subscribe, setSubscribe] = useState("");
    const [formMessage, setFormMessage] = useState("");

    const { 
        register,
        handleSubmit,
        control,
        getValues,
        getFieldState,
        formState: { isDirty, isValid, errors}
     } = useForm({
         mode: "onChange",
         resolver: yupResolver(SignupSchema),
         defaultValues: {
             fullname: "", states: "", email: "", numofapartment: "",
             typeofbuilding: "", gradeofmaterials: "", budget: "",
             floorfinish: "", ceilingfinish: "", interiorwallfinish: "", exteriorwallfinish: "", windows: "", doors: "", roofingmaterials: "",
             roomsizefactor: "", livingrooms: "", numofbedrooms: "", numofkitchens: "", numofdinning: "", numoftoilet: "", numofotherrooms: "", 
         }
     });
     //console.error(errors)
     const { dirtyFields } = useFormState({
        control
      });

    
    const onSubmit = async data => { 
        //console.log(data); 
    };

    const handleRegistration = () => {
        handleSubmit(onSubmit)
        setStep(6)
    }

    const subscribeHandler =(e)=> {
        setSubscribe(e.target.value)
    }

    // const client = new SMTPClient({
    //     user: 'user',
    //     password: 'password',
    //     host: 'smtp.ypur-email.com',
    //     ssl: true
    // })

    const submitHandler =(e)=> {
        e.preventDefault();
        if(subscribe) {
            //console.log(subscribe);
            // add to firebase
             addDoc(collection(db, "emails"), {
                email: subscribe,
                time: serverTimestamp()
              });
              setSubscribe('');
              setFormMessage("Your subscription for our latest insights was successful! 🎉🎉");
              setTimeout(() => {
                  setFormMessage("")
              }, 3000);
        }
    }

    const rootComments = backendComments.filter(
        backendComment => backendComment.parentId === null
    );
    
    const getReplies = (commentId) =>
    backendComments
      .filter((backendComment) => backendComment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

    const addComment = (fullname, text, emailAddress,  parentId) => {
        //console.log('addComment', fullname, parentId, text, emailAddress)
        createCommentApi(fullname, text, emailAddress, parentId).then(comment=> {
            setBackendComments([comment, ...backendComments])
            setActiveComment(null);
        });
    }

    const deleteComment = commentId => {
        if (window.confirm('Are you sure that you want to remove comment?')) {
            deleteCommentApi(commentId).then(()=> {
                const updatedBackendComments = backendComments.filter(backendComment => backendComment.id !== commentId);
                setBackendComments(updatedBackendComments)
            })
        }
    }

    //console.log("backendComments", backendComments)
    useEffect(()=> {
        getCommentsApi().then(data => {
            setBackendComments(data)
        })
    },[])
    
    
    const onClick1 =()=> {
        const fullname = dirtyFields.fullname
        const states = dirtyFields.states
        const email = dirtyFields.email
        const numbers = dirtyFields.numofapartment

        const fieldState = getValues("fullname");

        //console.log({'fullname': fullname, 'state': states, 'email': email, 'numofapart': numbers, 'fieldstate': fieldState})
        if (dirtyFields.fullname && dirtyFields.email && dirtyFields.states && dirtyFields.numofapartment) {
            setStep(2)
        }
    }

    const onClick2 =()=> {
        const typeofbuilding = dirtyFields.typeofbuilding
        const gradeofmaterials = dirtyFields.gradeofmaterials
        const budget = dirtyFields.budget

        //console.log({"typeofbuilding": typeofbuilding, "gradeofmaterials": gradeofmaterials, "budget": budget})

        if(dirtyFields.typeofbuilding && dirtyFields.gradeofmaterials && dirtyFields.budget) {
            setStep(3)
        }
    }

    const onClick3=()=> {
        const floorfinish = dirtyFields.floorfinish
        const ceilingfinish = dirtyFields.ceilingfinish
        const interiorwallfinish = dirtyFields.interiorwallfinish
        const exteriorwallfinish = dirtyFields.exteriorwallfinish
        const windows = dirtyFields.windows
        const doors = dirtyFields.doors
        const roofingmaterials = dirtyFields.roofingmaterials

        // console.log({"floorfinish": floorfinish, "ceilingfinish": ceilingfinish,
        // "interiorwallfinish": interiorwallfinish,  "exteriorwallfinish": exteriorwallfinish,
        // "windows": windows, "doors": doors, "roofingmaterials": roofingmaterials})

        if(dirtyFields.floorfinish && dirtyFields.ceilingfinish && dirtyFields.interiorwallfinish && dirtyFields.exteriorwallfinish && dirtyFields.windows && dirtyFields.doors && dirtyFields.roofingmaterials) {
                setStep(4)
            }
    }
    
    const onClick4=()=> {
        const roomsizefactor = dirtyFields.roomsizefactor
        const livingrooms = dirtyFields.livingrooms
        const numofbedrooms = dirtyFields.numofbedrooms
        const numofkitchens = dirtyFields.numofkitchens
        const numofdinning = dirtyFields.numofdinning
        const numoftoilet = dirtyFields.numoftoilet
        const numofotherrooms = dirtyFields.numofotherrooms

        // console.log({"roomsizefactor": roomsizefactor, "livingrooms": livingrooms,
        // "numofbedrooms": numofbedrooms,  "numofkitchens": numofkitchens,
        // "numofdinning": numofdinning, "numoftoilet": numoftoilet, "numofotherrooms": numofotherrooms})

        if(dirtyFields.roomsizefactor && dirtyFields.livingrooms && dirtyFields.numofbedrooms && dirtyFields.numofkitchens && 
            dirtyFields.numofdinning && dirtyFields.numoftoilet && dirtyFields.numofotherrooms) {
                setStep(5)
            }
    }

    
    return(
        <div>
            <MultiStepContext.Provider value={{rootComments, getReplies, addComment, activeComment, setActiveComment,
                 deleteComment, backendComments, fullname,
                  setFullname, setBackendComments, text, subscribe,
                   setSubscribe, subscribeHandler, submitHandler, 
                   setText, emailAddress, setEmailAddress, onClick1, onClick2, onClick3, formMessage, 
                    onClick4, getValues, steps, currentStep,
                     setStep, date, setDate, errors, register,
                      handleSubmit, handleRegistration, Controller, control }}>
                {children}
            </MultiStepContext.Provider>
        </div>
    );
}

export default FormContext;